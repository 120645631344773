import { ILogObj, Logger } from 'tslog';

export const rootLogger = new Logger({ name: 'app' });

const transports: Array<(logObj: ILogObj) => void> = [];

export const attachTransport = function (cb: (logObj: ILogObj) => void) {
  transports.push(cb);
};

rootLogger.attachTransport((logObj) => {
  transports.forEach((cb) => cb(logObj));
});

export const getLogger = function (name: string) {
  return rootLogger.getSubLogger({ name });
};
