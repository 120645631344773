export const API_URL = import.meta.env.VITE_APP_API_URL ?? '';
export const PLAUSIBLE_DOMAIN = import.meta.env.VITE_APP_PLAUSIBLE_DOMAIN;
export const PLAUSIBLE_API = import.meta.env.VITE_APP_PLAUSIBLE_API;
export const DEVTOOLS_ENABLED = import.meta.env.VITE_APP_DEVTOOLS === 'true';
export const LANDING_PAGE_URL = import.meta.env.VITE_APP_LANDING_PAGE_URL || '';
export const IS_DEVELOPMENT = !import.meta.env.PROD;
export const ENVIRONMENT = import.meta.env.MODE;
export const VERSION = import.meta.env.VITE_APP_VERSION || import.meta.env.MODE;
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID;

export const PAYMENT_ENABLED =
  import.meta.env.VITE_APP_PAYMENT_ENABLED === 'true';

export const STRIPE_PRICE_ID_WEEKLY =
  import.meta.env.VITE_APP_STRIPE_PRICE_ID_WEEKLY ?? '';
export const STRIPE_PRICE_ID_MONTHLY =
  import.meta.env.VITE_APP_STRIPE_PRICE_ID_MONTHLY ?? '';
export const STRIPE_BILLING_PORTAL_URL =
  import.meta.env.VITE_APP_STRIPE_BILLING_PORTAL_URL ?? '';

export const LINKEDIN_ENABLED =
  import.meta.env.VITE_APP_LINKEDIN_ENABLED === 'true';

export const GOOGLE_TAG_ID = import.meta.env.VITE_APP_GOOGLE_TAG_ID;

export const UI_LANGUAGES = ['en', 'de', 'fr', 'es', 'it', 'pt'] as const;
